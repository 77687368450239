<template>
  <Component
    :is="getWidget(widget)"
    v-for="(widget, index) in widgets"
    :id="`widget-${widget.id}`"
    :key="widget.id"
    class="widget-margins"
    :class="[
      `widget-mt-${widget.margin_top ?? 'none'}`,
      `widget-mb-${widget.margin_bottom ?? 'none'}`
    ]"
    :widget="widget"
    :is-first="index === 0"
    :lazy-image="index !== 0"
  />
</template>

<script setup lang="ts">
import type { Component as ConcreteComponent } from 'vue'

import type { PublicWidget } from '@/service/__generated-api'

defineProps<{ widgets: PublicWidget[] }>()

const getWidget = (widget: PublicWidget) =>
  resolveComponent(`Widgets${widget.resourcetype}`) as ConcreteComponent
</script>
